import { Component } from 'solid-js'
import { CollaboratorEntryProps } from '~/components/collaborator-entry/collaborator-entry.interfaces'
import styles from '~/components/collaborator-entry/collaborator-entry.module.scss'
import { Role } from '~/services/database/permissions'
import { getUserDisplayName } from '~/services/database/users'

const CollaboratorEntry: Component<CollaboratorEntryProps> = ({ user, actionLabel, onClick }) => {
  const role = new Role(user?.preferred_role)
  const roleClasses = [styles.role, styles[role.value]].join(' ')

  return (
    <div class={styles.collaboratorEntry} title={role.label}>
      <div class={styles.about}>
        <div class={roleClasses}>
          <span>{role.label}</span>
        </div>
        <div class={styles.name}>
          {getUserDisplayName(user)}
        </div>
      </div>
      <button
        class={styles.action}
        onClick={onClick}
      >
        {actionLabel}
      </button>
    </div>
  )
}

export default CollaboratorEntry
